<template>
  <auth-layout>
    <v-container>
      <loader v-if="isLoading" />
      <page-section v-else class="text-center mx-auto max-w-lg">
        <page-header>
          <page-title>
            Confirmare comanda
            <span class="text-yellow-600">#{{ id }} </span>
          </page-title>
        </page-header>
        <v-card class="p-20 mb-4">
          <h2 class="mb-4 text-xl font-medium">Status comanda</h2>
          <h4 class="mb-4 uppercase text-md text-yellow-600 font-medium">
            {{ orderStatus }}
          </h4>
          <h2 class="mb-4 text-xl font-medium">Status plata</h2>
          <h4 class="mb-4 uppercase text-md text-yellow-600 font-medium">
            {{ paymentStatus }}
          </h4>
          <p class="mb-4 font-bold text-3xl">Va multumim!</p>
        </v-card>
        <router-link
          to="/"
          class="
            text-md text-gray-700
            hover:text-gray-900
            border-b border-transparent
            hover:border-gray-900 hover:border-current
            capitalize
          "
        >
          Inapoi
          <span class="font-bold">acasa</span>
        </router-link>
      </page-section>
    </v-container>
  </auth-layout>
</template>

<script>
import checkoutsApi from "../api/resources/checkoutsApi";
import VCard from "../components/VCard.vue";
import PageHeader from "../components/PageHeader.vue";
import AuthLayout from "../layout/AuthLayout.vue";
import PageTitle from "../components/PageTitle.vue";
import Loader from "../components/Loader.vue";
import VContainer from "../components/VContainer.vue";
import PageSection from "../components/PageSection.vue";

export default {
  components: {
    VCard,
    PageHeader,
    AuthLayout,
    PageTitle,
    Loader,
    VContainer,
    PageSection,
  },
  data() {
    return {
      id: "",
      orderStatus: "N/A",
      isLoading: false,
      paymentStatus: "N/A",
    };
  },

  created() {
    this.isLoading = true;
    checkoutsApi
      .thanks(this.$route.query)
      .then((response) => {
        this.id = response.data.order_id;
        this.orderStatus = response.data?.order_status;
        this.paymentStatus = response.data?.payment_status;
        this.isLoading = false;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.$router.push({ name: "notFound" });
        }
      });
  },
};
</script>

<style></style>
